.root {
    margin: 0 auto;
    max-width: 947px;

    strong {
        display: inline-block;
        width: 100%;
        font-size: 24px;
        margin-top: var(--spacing-core-6);
        margin-bottom: var(--spacing-core-2);
    }
}
